/**
 * Nosy Page Template
 *
 * @export Page
 */
import { Container, Heading, useBreakpointValue } from '@chakra-ui/react'
import { PortableText } from '@portabletext/react'
import { graphql } from 'gatsby'
import React from 'react'
import Image from '../../components/image/image'
import Layout from '../../components/layout/layout'
import SEO from '../../components/seo/seo'
import { defaultSerializer } from '../../serializers'

// Query variables passed in from pageContext
export const query = graphql`
    query TEMPLATE_PAGE_QUERY($id: String!) {
        sanityPage(id: { eq: $id }) {
            title
            seo: _rawSeo(resolveReferences: { maxDepth: 10 })
            # excerpt: _rawExcerpt(resolveReferences: { maxDepth: 10 })
            body: _rawBody(resolveReferences: { maxDepth: 10 })
            mainImage {
                ...ImageWithPreview
            }
            slug {
                current
            }
        }
    }
`

const Page = ( props ) => {
    // console.log('🦄 ~ file: page.js ~ line 34 ~ Page ~ props', { props })
    const {
        data: {
            sanityPage: {
                title = '',
                seo: { 
                    seoTitle = '', 
                    seoDescription = ''
                },
                body = [],
                // excerpt = [],
                mainImage = {},
                slug: { current: url = '' }
            }
        }
    } = props

    const imageWidths = useBreakpointValue( {
        base: 447, 
        sm: 735,
        md: 959,
        lg: 1120
    } )

    return (
        <Layout>
            <SEO
                title={seoTitle}
                description={seoDescription}
                image={mainImage}
                url={`/${url}/`}
                type='article' />
            <Container maxWidth='6xl'>
                <Container maxWidth='4xl' py={6}>
                    <Heading 
                        as='h1' 
                        fontSize={[
                            '4xl',
                            '5xl',
                            '6xl'
                        ]}
                        wordBreak='break-word'
                        hyphens='auto'
                        fontWeight='extrabold'
                        color='gray.100'
                        my={10}>
                        {title}
                    </Heading>
                </Container>
                { mainImage && (
                    <Image
                        imageData={mainImage}
                        loading='eager'
                        width={imageWidths}
                        height={500}
                        mb={12}
                        sx={{ width: 'full' }} />
                )}
                <Container maxWidth='4xl' py={6}>
                    <PortableText
                        value={body}
                        components={defaultSerializer} />
                </Container>
            </Container>
        </Layout>
    )
}

export default Page
